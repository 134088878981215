import React from 'react';
import Booking from './ModuleResa/ModuleResa'; // Assurez-vous que le chemin est correct

const Test = ({ content, language }) => {


  return (
    <div className="section">
      {/* Utilisation du module Booking avec rendu conditionnel en fonction de la largeur */}
        <Booking content={content} language={language} />
    </div>
  );
};

export default Test;
