import React, { useState, useEffect } from 'react';
import CROIX from '../../assets/croix.png';
import './moduleresa.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Styles par défaut du calendrier
import { ReactComponent as NextIcon } from '../../assets/droite.svg';  // Importer un SVG pour Next
import { ReactComponent as PrevIcon } from '../../assets/gauche.svg';

// Liste des hôtels disponibles
const hotels = [
  { id: 22993, name: "MONSIEUR GEORGE*****  PARIS 8" },
  { id: 28793, name: "HÔtel Hana*****  Paris 2" },
  { id: 1287, name: "MONSIEUR CADET****  PARIS 9" },
  { id: 19034, name: "MAISON SAINTONGE****  PARIS 3" },
  { id: 347, name: "MONSIEUR ARISTIDE, PARIS****  18" },
  { id: 5956, name: "HÔTEL DES Académies et des arts****  PARIS 6" },
  { id: 13829, name: "CAP D’ANTIBES BEACH HOTEL*****  CAP D’ANTIBES" },
  { id: 25266, name: "HÔTEL LA PONCHE*****  SAINT-TROPEZ" },
];

const hotelCodes = {
  22993: "J272",
  28793: "J7GU",
  1287: "2UGT",
  19034: "2Q2P",
  347: "JR59",
  5956: "2M4G",
  13829: "JK7H",
  25266: "JRU8",
};

const BookingModule = ({ onClose }) => {
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [adultCount, setAdultCount] = useState(2);
  const [childCount, setChildCount] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showHotels, setShowHotels] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Suivi de la largeur de la fenêtre

  // Mettre à jour la largeur de la fenêtre lorsqu'elle change
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleHotelClick = (hotelId) => {
    setSelectedHotel(hotelId);
    setShowHotels(false);
  };

  const handleSubmit = () => {
    const hotel = hotels.find(h => h.id === selectedHotel);
    const hotelCode = hotelCodes[selectedHotel] || 'JRU8';

    const formattedHotelName = hotel.name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase()
      .replace(/\s+/g, '')
      .replace(/[^a-zA-Z0-9]/g, '');

    const formatDate = (date) => {
      if (date && date instanceof Date && !isNaN(date)) {
        return date.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
      }
      return '';
    };

    const formattedArrivalDateString = formatDate(arrivalDate);
    const formattedDepartureDateString = formatDate(departureDate);

    if (!formattedArrivalDateString) {
      alert("Veuillez sélectionner une date d'arrivée valide.");
      return;
    }

    const url = `https://www.secure-hotel-booking.com/d-edge/${formattedHotelName}/${hotelCode}/fr-FR?selectedAdultCount=${adultCount}&selectedChildCount=${childCount}&arrivalDate=${formattedArrivalDateString}&departureDate=${formattedDepartureDateString}&promocode=${promoCode}`;

    window.location.href = url;
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      setArrivalDate(startDate);
      setDepartureDate(endDate);
    }
    setShowCalendar(false);
  };

  return (
    <div className="ultraBigContainerResa">
      <button className="closeButton" onClick={onClose}>
        <img src={CROIX} alt="Close" className="croixResa" />
      </button>
      <div className="booking-module">
        <h2>Réserver une chambre</h2>

        <div className="ligneModule1">
          <h3 onClick={() => setShowHotels(!showHotels)}>Hôtels</h3>
          <div className="hotel-button" onClick={() => setShowHotels(!showHotels)}>
            {selectedHotel ? (
              <span className="selected-hotel">
                {hotels.find(hotel => hotel.id === selectedHotel)?.name}
              </span>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                <path d="M1 1L8 11L11.5 6L15 1" stroke="black" strokeWidth="1.5" />
              </svg>
            )}
          </div>
        </div>

        <div className={`hotel-list ${showHotels ? 'show' : ''}`}>
          {hotels.map((hotel) => (
            <button
              key={hotel.id}
              className={`hotel-button list-hotel-calendar ${hotel.id === selectedHotel ? 'selected' : ''}`}
              onClick={() => handleHotelClick(hotel.id)}
            >
              {hotel.name} <span className="voyager-text">Voyager</span>
            </button>
          ))}
        </div>

        <div className="ligneModule2">
          <h3>Dates</h3>
          <div className="date-input" onClick={() => setShowCalendar(!showCalendar)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
              <path d="M1 1L8 11L11.5 6L15 1" stroke="black" strokeWidth="1.5" />
            </svg>
            <span className="resultDate">
              {arrivalDate instanceof Date && !isNaN(arrivalDate) ? arrivalDate.toLocaleDateString() : 'Arrivée'} -
              {departureDate instanceof Date && !isNaN(departureDate) ? departureDate.toLocaleDateString() : 'Départ'}
            </span>
          </div>
        </div>

        {showCalendar && (
          <div className="calendar-container">
            <Calendar
              onChange={handleDateChange}
              value={arrivalDate && departureDate ? [arrivalDate, departureDate] : arrivalDate || departureDate || null}
              selectRange={true}
              showDoubleView={windowWidth >= 1024} // Déterminer si showDoubleView est true ou false
              minDate={new Date()}
              goToRangeStartOnSelect={false}
              activeStartDate={activeStartDate}
              onActiveStartDateChange={(data) => {
                if (data.action !== "onChange") {
                  setActiveStartDate(data.activeStartDate);
                }
              }}
              nextLabel={<NextIcon />}
              prevLabel={<PrevIcon />}
            />
          </div>
        )}

        <div className="ligneModule3">
          <h3>Nombre d'adultes</h3>
          <div className="form-group adulte">
            <input
              type="number"
              id="adultCount"
              value={adultCount}
              onChange={(e) => setAdultCount(Math.max(1, parseInt(e.target.value, 10)))}
              min="1"
              className="no-spinner"
            />
          </div>
        </div>

        <div className="ligneModule4">
          <h3>Nombre d'enfants</h3>
          <div className="form-group enfant">
            <input
              type="number"
              id="childCount"
              value={childCount}
              onChange={(e) => setChildCount(Math.max(0, parseInt(e.target.value, 10)))}
              min="0"
              className="no-spinner"
            />
          </div>
        </div>

        <div className="ligneModule5">
          <h3>Code promo</h3>
          <div className="form-group promo">
            <input
              type="text"
              id="promoCode"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
        </div>
        <button onClick={handleSubmit} className="submit-button">
          Voir les tarifs
        </button>
      </div>
    </div>
  );
};

export default BookingModule;
